import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@providers/config/config';
import { DeviceDetectorService } from '@providers/device-ks/device-detector.service';
import { filter, take } from 'rxjs/operators';

import { CountryService } from '../country/country';
import { LocationService } from '../helpers/location';
import { MetricsService } from '../metrics/metrics.service';

@Injectable()
export class TranslateInitializer {
  currentLangSet = 'en';
  constructor(
    private country: CountryService,
    private translate: TranslateService,
    private location: LocationService,
    private metrics: MetricsService,
    private deviceDetection: DeviceDetectorService
  ) {}

  load(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.country.currentCountry) {
        await this.country.country
          .pipe(
            filter(country => !!country),
            take(1)
          )
          .toPromise();
      }

      this.initTranslate();
      resolve(true);
    });
  }

  initTranslate() {
    // Set the default language for translation strings, and the current language.

    const defaultLang = 'en';
    const customLang = this.location.getUrlParameter('lang');
    this.translate.setDefaultLang(defaultLang);
    const lang = this.translate.getBrowserLang();
    const rexgLangs = /(en|es|pt)/;

    const match = (customLang || lang || '').match(rexgLangs);
    let currentLang = match ? match[0] : defaultLang;

    const countryLang = Config.countriesConfig[this.country.currentCountry]?.lang;

    if (countryLang) {
      currentLang = countryLang;
    }

    if (this.deviceDetection.checkIfMovistar()) {
      currentLang = 'es';
    }

    this.currentLangSet = currentLang ? currentLang.slice(0, 2) : defaultLang;
    this.translate.use(currentLang);
    this.registerLanguageOnMetrics();
    this.translate.getBrowserCultureLang();
  }
  getCurrentLang() {
    return this.currentLangSet;
  }

  registerLanguageOnMetrics() {
    this.metrics.register({ 'Kanto Language': this.currentLangSet });
  }
}

export const translateInitializerFactory = (service: TranslateInitializer) => () => service.load();
