const changeToMobileEventNameObject = {
  abrioApp: 'OpenApp',
  openSesion: 'OpenSession',
  createAccount: 'CreateAccount',
  PageViewAccount: 'ViewAccount',
  PageViewExplore: 'ViewMusicBook', //CONSULTAR SI SE DEBE AGREGAR O NO ¿Por que? Por que no se lanza precisamente en el explora de mobile
  playSong: 'PickSong',
  searched: 'SearchPerformed',
  favorites: 'AddToPlaylist',
  addFavoriteSong: 'AddToFavorite',
  addFavoriteArtist: 'AddToArtist',
  singSong: 'SingSong',
  executePay: 'TryToPay',
  pasarelaExito: 'SuccessPayment',
  pasarelaError: 'ErrorPurchaseApp',
  recoveryPassword: 'ForgotPassword',
  //'PageViewExplore': 'ViewBrowse', //ESTE O EL OTRO DE MAS ARRIBA
  PageViewProfile: 'Ks518LoadedProfile',
  btnPlaylistExplore: 'ViewPlaylist',
  logout: 'Logout',
  pasarelaInfoView: 'ViewPayment',
  FinishScore: 'ScoreResult',
};

export const changeToMobileEventName = (event: string) => (changeToMobileEventNameObject[event] || event) as string;

export const changeToMobilePropsName = (event: string, data: Record<string, any>) => {
  const withDeletedProps = { ...data };
  const country: string = data['Kanto Country'] || data['Kanto User Country'];

  const parsedProps = {
    ...(data['Kanto Country'] && { Country: data['Kanto Country'] }),
    ...(data['Kanto User Country'] && { Country: data['Kanto User Country'] }),
    ...(data['Membership'] && { Premium: data['Membership'] === 'Free' ? false : true }),
    ...(data['Kanto Language'] &&
      country && { language: `${data['Kanto Language'].toLowerCase()}-${country.toUpperCase()}` }),
    ...(data['Email'] && { Email: data['Email'] }),
    ...(data['email'] && { Email: data['email'] }),
    ...(data['Kanto Email'] && { Email: data['Kanto Email'] }),
    ...(data['keywork'] && { Content: data['keywork'] }),
    ...(data['search_keyword'] && { Content: data['search_keyword'] }),
    ...(data['Premium'] && { Premium: data['Premium'] }),
    ...(data['idUser'] && { UserId: data['idUser'] }),
    ...(data['Where Session'] && { How: data['Where Session'] }),
    ...(data['artist'] && { Artist: data['artist'] }),
    ...(data['idSong'] && { IdSong: data['idSong'] }),
    ...(event === 'playSong' && data['song'] && { Name: data['song'] }),
    ...(event === 'singSong' && data['song'] && { SongName: data['song'] }),
    ...(event === 'favorites' && data['name'] && { PlaylistName: data['name'] }),
    ...(event === 'favorites' && data['id'] && { Id: data['id'] }),
    ...(data['qr'] && { SessionCode: data['qr'] }),
    ...(data['Session QR Code'] && { SessionCode: data['Session QR Code'] }),
    ...(data['Plan ID'] && { PlanId: data['Plan ID'] }),
    ...(event === 'btnPlaylistExplore' && data['id'] && { PlaylistId: data['name'] }),
    ...(data['Token Session'] && { SessionToken: data['Token Session'] }),
  };

  delete withDeletedProps['Kanto Country'];
  delete withDeletedProps['Kanto User Country'];
  delete withDeletedProps['Membership'];
  delete withDeletedProps['Kanto Language'];
  delete withDeletedProps['Email'];
  delete withDeletedProps['email'];
  delete withDeletedProps['Kanto Email'];
  delete withDeletedProps['keywork'];
  delete withDeletedProps['Premium'];
  delete withDeletedProps['idUser'];
  delete withDeletedProps['Where Session'];
  delete withDeletedProps['artist'];
  delete withDeletedProps['idSong'];
  delete withDeletedProps['song'];
  delete withDeletedProps['search_keyword'];
  if (event === 'favorites') {
    delete withDeletedProps['name'];
    delete withDeletedProps['id'];
  }
  delete withDeletedProps['qr'];
  delete withDeletedProps['Session QR Code'];
  delete withDeletedProps['Plan ID'];
  if (event === 'btnPlaylistExplore') {
    delete withDeletedProps['id'];
  }

  const mergedProps = { ...withDeletedProps, ...parsedProps };

  return mergedProps;
};
