/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

import { keyCodes } from '@app/pages/login/keycodes';
import { environment } from '@env/environment';
import { findIndex, isEmpty, some } from 'lodash-es';

import { LetterAvatar } from '../letterAvatar/letterAvatar';

export const isURL = (str: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return pattern.test(str);
};
export const startWithHTTP = (str: string) => str.substring(0, 4) === 'http';

export const maxScoreValue = 180;
export const minScoreValue = 0;
export const maxUserScoreValue = 5;
export const minUserScoreValue = 0;
export const powerUserScoreValue = 1;
export const factorUserValue = () => (maxUserScoreValue + powerUserScoreValue) / maxScoreValue;

export const swapArrayLocs = (arr, index1, index2) => {
  const temp = arr[index1];

  arr[index1] = arr[index2];
  arr[index2] = temp;
  return arr;
};

export const getUserScoreFromValue = (value: any) => {
  const userScore = value * factorUserValue();
  return getValueInRange(minScoreValue, maxUserScoreValue, userScore);
};
export const toSimpleObject = (item: any) => JSON.parse(JSON.stringify(item));

export const scoreToZone = (score: any) => Math.ceil(getUserScoreFromValue(score));
export const getParameterByName = (name: any, url?: any) => {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};
export const getAverageFromLast = (lastAverage: any, newValue: any, counter: any) =>
  ((lastAverage || 0) * (counter || 0) + newValue) / ((counter || 0) + 1);
export const getValueInRange = (minValue: any, maxValue: any, value: any) =>
  Math.min(maxValue, Math.max(minValue, value));

export const getDifferenceTime = (lastTime: Date, previousTime: Date) =>
  Math.round(Math.abs((lastTime.getTime() - previousTime.getTime()) / 1000));

export const getPictureUrlFromFBID = (fb_id: any, username?: any) => {
  if (fb_id && fb_id !== 'None') {
    return `https://graph.facebook.com/${fb_id}/picture?redirect=true&type=large&height=200&width=200`;
  }
  return LetterAvatar(username, 100);
};

export const getPictureUrlFromProfile = (user: Partial<{ img?: string; background?: string; fb_id?: string }>) => {
  if (user) {
    return (
      user.img ||
      user.background ||
      (user.fb_id && user.fb_id !== 'None'
        ? `http://graph.facebook.com/${user.fb_id}/picture?width=200&height=200`
        : './images/app/iconos/profile-panel.png')
    );
  }

  return './images/app/iconos/profile-panel.png';
};

export const isActionPlaying = (action: string) => {
  if (
    action === 'PlayOnPlaylist' ||
    action === 'PlayOnSearch' ||
    action === 'PlayOnFeed' ||
    action === 'PlayOnFeatured' ||
    action === 'PlayOnFeaturedSongDay'
  ) {
    return true;
  } else {
    return false;
  }
};

export const isActionPlayingQueue = (action: string) => {
  if (action === 'PlayOnExplore' || action === 'PlayQueue') {
    return true;
  } else {
    return false;
  }
};

export const getLinkVideo = (video: any) => {
  const localDir = 'http://localhost:8887/';
  // let localDir='http://192.168.1.240:8887/';
  // let localDir='http://192.168.0.34:8887/';
  // let localDir='http://192.168.56.1:8887/';
  if (video && video.video) {
    if (startWithHTTP(video.video)) {
      return video.video;
    } else {
      return localDir + video.video;
    }
  }
  return null;
};

export const objectWithoutAttrEmpty = objectToValidate => {
  const validatedObject: any = {};
  some(objectToValidate, (data, att) => {
    if (!isEmpty(data)) {
      validatedObject[att] = data;
    }
  });
  return validatedObject;
};

export const intervalTime = time => {
  if (time < 6) {
    return '[0-5]';
  }
  if (time > 5 && time < 11) {
    return '[6 -10]';
  }
  if (time > 10 && time < 16) {
    return '[11-15]';
  }
  if (time > 15 && time < 21) {
    return '[16-20]';
  }
  if (time > 20 && time < 26) {
    return '[21-25]';
  }
  if (time > 25 && time < 36) {
    return '[26-35]';
  }
  if (time > 35 && time < 46) {
    return '[36-45]';
  }
  if (time > 45 && time < 66) {
    return '[46-65]';
  }
  if (time > 65 && time < 86) {
    return '[66-85]';
  }
  if (time > 85 && time < 136) {
    return '[86-135]';
  }
  if (time > 135 && time < 186) {
    return '[136-185]';
  }
  if (time > 185 && time < 201) {
    return '[186-200]';
  }
  if (time > 200) {
    return '[mayor a 200]';
  }
  return undefined;
};

export const fancyTimeFormat = time => {
  // Hours, minutes and seconds
  const hrs = Math.floor(time / 3600);
  const mins = Math.floor((time % 3600) / 60);
  const secs = Math.floor(time % 60);

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '0';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
};

export const elementHasClass = (element, cls) => (' ' + element.className + ' ').indexOf(' ' + cls + ' ') > -1;

export const elementHasClassFromSelector = (selector, cls) => {
  const element = document.querySelector(selector);
  if (element) {
    return elementHasClass(element, cls);
  }
  return false;
};

export const getWebDomain = (country: string) => {
  const domains = {
    ES: environment.webDomains.ES,
    US: environment.webDomains.default,
    default: environment.webDomains.default,
  };

  return domains[country] || domains.default;
};

export const getWebUrl = (service: string, country: string) => `https://${getWebDomain(country)}/${service}`;

export const isAllowedKeyCode: (key: { keyCode: number; key: string }) => boolean = (key: {
  keyCode: number;
  key: string;
}) =>
  findIndex(
    keyCodes,
    code =>
      (key.keyCode === code.keycode || code.key === key.key) && (code.type !== 'short' || code.key === 'Backspace')
  ) !== -1;

export interface IDebounce<T extends (...args: any[]) => any> {
  debounced: (...args: Parameters<T>) => void;
  cancel: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, prefer-arrow/prefer-arrow-functions
export function debounce<T extends (...args: any[]) => void>(func: T, delay: number): IDebounce<T> {
  let timer: ReturnType<typeof setTimeout>;

  const debounced = (...args: Parameters<T>) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };

  const cancel = () => {
    clearTimeout(timer);
  };

  return { debounced, cancel };
}

export interface IDebounceAsync<T extends (...args: any[]) => any> {
  debounced: (...args: Parameters<T>) => Promise<ReturnType<T>>;
  cancel: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounceAsync = <T extends (...args: any[]) => any>(func: T, delay: number): IDebounceAsync<T> => {
  let timer: ReturnType<typeof setTimeout>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let rejectPending: ((reason?: any) => void) | null = null;

  const debounced = (...args: Parameters<T>): Promise<ReturnType<T>> => {
    //If already has a pending reject, cancel it
    if (rejectPending) {
      rejectPending('Debounced function was cancelled');
      rejectPending = null;
    }

    return new Promise<ReturnType<T>>((resolve, reject) => {
      clearTimeout(timer);

      //Save reject for cancel later
      rejectPending = reject;

      timer = setTimeout(() => {
        rejectPending = null; // Reset rejectPending
        try {
          const result = func(...args);
          if (result instanceof Promise) {
            result.then(resolve).catch(reject);
          } else {
            resolve(result);
          }
        } catch (error) {
          reject(error);
        }
      }, delay);
    });
  };

  const cancel = () => {
    clearTimeout(timer);
    if (rejectPending) {
      rejectPending('Debounced function was cancelled');
      rejectPending = null;
    }
  };

  return { debounced, cancel };
};
