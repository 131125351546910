import { Injectable } from '@angular/core';
import { DeviceKS } from '@providers/device-ks/device-ks';
import { AllowedDevicePlatformsType } from '@providers/device-ks/device-platform.interface';
import { LocationService } from '@providers/helpers/location';
import { LocalStorage } from '@providers/local-storage/local-storage';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TrackJS } from 'trackjs';

import { CountryService } from './country';

@Injectable()
export class CountryInitializer {
  constructor(
    private country: CountryService,
    private location: LocationService,
    private localStorage: LocalStorage,
    private deviceKS: DeviceKS
  ) {}

  load(): Promise<any> {
    return new Promise(async resolve => {
      const countryUrl = this.location.getUrlParameter('country');
      if (this.deviceKS.devicePlatform?.type === AllowedDevicePlatformsType.vizio) {
        this.country.setCountry('US');
      } else if (this.deviceKS.devicePlatform.type === AllowedDevicePlatformsType.movistar) {
        this.country.setCountry('ES');
      } else if (countryUrl) {
        this.country.setCountry(countryUrl);
      } else {
        await this.localStorage.$isReady.toPromise().then(() => {});
        const localStorageCountry: string = await this.localStorage.getItem('country');
        if (localStorageCountry) {
          this.country.setCountry(localStorageCountry);
        }
      }

      if (!this.country.isValidCountry(this.country.currentCountry)) {
        this.country
          .getCountry()
          .pipe(
            catchError(() => {
              this.country.setCountry('NN');
              return of('NN');
            })
          )
          .subscribe(country => {
            this.country.setCountry(country);
            this.country.registerCountryDataOnMetrics();
            TrackJS.addMetadata('country', this.country.currentCountry);
            resolve(this.country.currentCountry);
          });
      } else {
        this.country.registerCountryDataOnMetrics();
        TrackJS.addMetadata('country', this.country.currentCountry);
        resolve(this.country.currentCountry);
      }
    });
  }
}
