import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { pasarelaExitoInterface } from '@interfaces/pasarela-exito.interface';
import { AllowedDevicePlatformsType } from '@providers/device-ks/device-platform.interface';
import { PendingRequestService } from '@providers/pending-request/pending.request.service';
import { DeviceKS } from '@providers/providers';
import { find } from 'lodash-es';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, share, switchMap } from 'rxjs/operators';

import { AnonymousSessionInterface, UserSessionResponseInterface } from '../../interfaces/interfaces';
import { Config } from '../config/config';
import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';
import { RegisterWebService } from '../registerWeb/registerWeb';
import { SessionService } from '../user/session.service';
import { User } from '../user/user';

@Injectable()
export class KSFirebase {
  becomePremiumAction = new Subject<boolean>();
  remoteLoginAction = new Subject<boolean>();
  plans;
  plan;
  fullPlan;
  becomePremiumSubcription: Subscription;
  remoteLoginSubscription: Subscription;
  /**
   *
   * @type currentSession
   */
  currentSession: AngularFireObject<any>;
  /**
   *
   * @type currentToken
   */
  currentToken: AngularFireObject<any>;
  /**
   *
   * @type currentQR
   */
  currentQR: AngularFireObject<any>;
  /**
   *
   * @type currentSessionHost
   */
  currentSessionHost: AngularFireObject<boolean>;
  /**
   *
   * @type currentStartedAt;
   */
  currentSessionStartedAt: AngularFireObject<any>;
  /**
   *
   * @type currentSessionGuest
   */
  currentSessionGuest: AngularFireList<any>;

  /**
   *
   * @type currentSessionScore
   */
  currentSessionScore: Observable<any>;
  /**
   *
   */
  currentSessionBecomePremium: Observable<boolean>;

  /**
   * listener pago
   *
   * @function type
   */
  constructor(
    private metrics: MetricsService,
    private db: AngularFireDatabase,
    private location: Location,
    private config: Config,
    private session: SessionService,
    private registerWeb: RegisterWebService,
    private localStorage: LocalStorage,
    private pendingRequestService: PendingRequestService,
    private userService: User,
    private deviceKs: DeviceKS
  ) {}

  async getNamePlan(cb, planId: number | string) {
    const responseFunct = plans => {
      setTimeout(() => {
        const fullPlan: any = find(plans, { id: parseInt(planId as string, 10) });
        if (fullPlan) {
          this.plan = fullPlan?.price?.type;
          this.fullPlan = fullPlan;
        }
        cb();
      }, 3000);
    };
    this.config
      .getSettings()
      .then(res => {
        responseFunct(res.plans);
      })
      .catch();
  }

  getCurrent(currentUser) {
    return currentUser ? (currentUser.data ? currentUser.data : currentUser) : null;
  }

  init() {
    this.session.session$
      .pipe(
        distinctUntilChanged(
          (previous, current) =>
            previous?.session_id === current?.session_id && previous?.is_anonymous === current?.is_anonymous
        )
      )
      .subscribe(async (session: AnonymousSessionInterface | UserSessionResponseInterface) => {
        console.log('KSFIREBASE : init() this.session.session$.subscribe', session);
        this.setCurrentQR(this.session.getQR());

        if (this.remoteLoginSubscription) {
          this.remoteLoginSubscription.unsubscribe();
        }

        await this.setCurrentSession(this.session.getSessionToken(session));

        if (this.becomePremiumSubcription) {
          this.becomePremiumSubcription.unsubscribe();
        }
        if (!this.userService.authenticated || (this.userService.authenticated && !this.userService.premium)) {
          this.becomePremiumSubcription = this.currentSessionBecomePremium
            .pipe(distinctUntilChanged())
            .subscribe(value => {
              if (value) {
                this.becomePremiumAction.next(value);
              }
            });
        }
      });
  }

  getRef(ref) {
    return this.db.database.ref(ref);
  }
  /**
   * Obtiene Sesion de Firebase a partir de token
   *
   * @param session_id
   * @returns
   */
  getSession(session_id): AngularFireObject<any> {
    const ref = this.getRef('Sessions/' + session_id);
    return this.db.object(ref);
  }

  /**
   * Obtiene el Plan Pagado desde Web de Firebase a partir del token
   *
   * @param session_id
   * @returns
   */
  getSessionPaidPlan(session_id): AngularFireObject<any> {
    const ref = this.getRef('Sessions/' + session_id + '/plan');
    return this.db.object(ref);
  }

  /**
   * Obtiene Host de Sesion
   *
   * @param session_id
   * @returns
   */
  getSessionHost(session_id): AngularFireObject<any> {
    const ref = this.getRef('Sessions/' + session_id + '/host');
    return this.db.object(ref);
  }

  /**
   * Obtiene startedAt de Sesion
   *  session_id
   *
   * @returns
   */
  getSessionStartedAt(): AngularFireObject<any> {
    const ref = this.getRef('Sessions/' + this.currentToken + '/startedAt');
    return this.db.object(ref);
  }

  /**
   * Obtiene la referencia become_premium
   *
   * @returns
   */
  getSessionBecomePremium(): AngularFireObject<boolean> {
    const ref = this.getRef('Sessions/' + this.currentToken + '/become_premium');
    return this.db.object(ref);
  }

  /**
   * Obtiene la referencia become_premium
   *
   * @returns
   */
  getSessionScore(): AngularFireObject<boolean> {
    const ref = this.getRef('Sessions/' + this.currentToken + '/currentPlayingScore/score');
    return this.db.object(ref);
  }

  /**
   * Obtiene listado de celulares
   *  session_id
   *
   */
  getSessionGuest(): AngularFireList<boolean> {
    const ref = this.getRef('Sessions/' + this.currentToken).child('guests');
    return this.db.list(ref);
  }

  /**
   * Obtiene la referencia del plan seleccionado
   * session_id
   *
   * @param plan integer
   * @returns
   */
  getSessionSelectedPlanForPurchase(): AngularFireObject<any> {
    const ref = this.getRef('Sessions/' + this.currentToken).child('selectedPlanForPurchase');
    return this.db.object(ref);
  }

  /**
   *
   * @returns
   */
  getPeopleSingingSongs(): AngularFireObject<any> {
    const ref = this.getRef('Songs').child('cant_songs');
    return this.db.object(ref);
  }

  setCurrentQR(qr) {
    this.currentQR = qr;
  }
  /**
   * asigna sesion actual a partir de token
   *
   * @param token
   * @returns
   */
  async setCurrentSession(token) {
    this.currentToken = token;
    this.currentSession = this.getSession(token);
    this.currentSessionHost = this.getSessionHost(token);
    this.currentSessionStartedAt = this.getSessionStartedAt();
    this.currentSessionGuest = this.getSessionGuest();
    const planFromWeb = this.getSessionPaidPlan(token);
    this.currentSessionScore = this.getSessionScore()
      .valueChanges()
      .pipe(
        switchMap(value => of(value)),
        share()
      );

    this.currentSessionBecomePremium = this.getSessionBecomePremium()
      .valueChanges()
      .pipe(
        filter(() => !this.pendingRequestService.pendingCheckoutRequest),
        distinctUntilChanged(),
        switchMap(value =>
          planFromWeb.valueChanges().pipe(
            map(plan => ({
              becomePremium: value,
              plan,
            }))
          )
        ),
        switchMap(value => {
          if (value.becomePremium && this.deviceKs.devicePlatform.name !== AllowedDevicePlatformsType.vizio) {
            // This event is only sent when the WEB payment is successful and
            // the user becomes a premium user.
            const event = () => {
              const data: pasarelaExitoInterface = {
                'Where Paid': 'webmobile',
                'Payment Method': this.session.currentRealTimeSession.payment_method,
                'Payment Gateway': this.session.currentRealTimeSession.payment_gateway,
                Plan: this.fullPlan,
                'Plan Code': this.fullPlan?.price?.type,
                'Plan Name': this.fullPlan?.name,
                'Plan ID': this.fullPlan?.id,
              };
              this.metrics.sendEvent(MetricsService.eventNames.pasarelaExito, data);
            };
            this.getNamePlan(event, value.plan.id);
          }
          return of(!!value.becomePremium);
        }),
        share()
      );
    this.remoteLoginSubscription = this.currentSessionHost
      .valueChanges()
      .pipe(
        filter(() => !this.pendingRequestService.pendingSignupRequest),
        distinctUntilChanged()
      )
      .subscribe(value => {
        console.log('se ejecuta el host value changes');
        console.log(this.location.path().indexOf('login/app'), 'login/app 2', value);
        if (value) {
          this.registerWeb.initSessionByQR(this.currentQR).then(loginOk => {
            if (loginOk) {
              this.remoteLoginAction.next(loginOk);
              this.remoteLoginSubscription?.unsubscribe();
            }
          });
        }
      });
  }

  getCurrentToken() {
    return this.currentToken;
  }

  getCurrentSession() {
    return this.currentSession;
  }

  getReceiveSmsObject() {
    const ref = this.getRef('Sessions/' + this.currentToken + '/receive_sms');
    return this.db.object(ref);
  }

  getReceiveSmsObservable() {
    const ref = this.getRef('Sessions/' + this.currentToken + '/receive_sms');
    return this.db.object(ref).valueChanges();
  }
}
