import { Injectable } from '@angular/core';
import { MetricsService } from '@providers/metrics/metrics.service';

@Injectable()
export class RootScopeAdapter {
  nameStatesObjectComplete = {
    toptrack: 'btnExplora',
    moods: 'ViewModos',
    login: 'btnRegister',
    'login.tv': 'btnRegister',
    'login.sms': 'btnRegister',
    'login.web': 'btnRegister',
    'login.fb': 'btnRegister',
    catalog: 'btnCatalog',
    connect: 'btnConnect',
    search: 'btnSearch',
    user: 'btnUser',
    profile: 'btnUser',
    coupons: 'btnCoupon',
    couponsLg: 'btnCoupon',
    player: 'ViewPlayer',
    subscription: 'btnPayment',
    playlist: 'ViewPlaylist',
    whypremium: 'ViewWhypremium',
    PlayQueue: 'PlayQueue',
  };
  constructor(private metrics: MetricsService) {}

  sendViewClicked(name, data?) {
    this.metrics.viewClicked(this.nameStatesObjectComplete[name], data);
  }
}
