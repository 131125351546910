import { activeFastPlay, goOutApp, lastSearchField, setLoading } from '@app/state/actions/global-scope.actions';
import { GlobalScopeState, initialGlobalScopeState } from '@app/state/app.state';
import { createReducer, on } from '@ngrx/store';

const globalScopeReducerFn = createReducer(
  initialGlobalScopeState,
  on(goOutApp, (state: GlobalScopeState, { goOutAppValue }) => ({
    ...state,
    goOutApp: goOutAppValue,
  })),
  on(activeFastPlay, (state: GlobalScopeState, { activeFastPlayValue }) => ({
    ...state,
    activeFastPlay: activeFastPlayValue
  })),
  on(lastSearchField, (state: GlobalScopeState, { lastSearchFieldValue }) => ({
    ...state,
    lastSearchField: lastSearchFieldValue,
  })),
  on(setLoading, (state: GlobalScopeState, { isLoadingValue }) => ({
    ...state,
    isLoading: isLoadingValue,
  })),
);
export const globalScopeReducer = (state, action) => globalScopeReducerFn(state, action);
