import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CookiesService } from '@providers/cookies/cookies.service';
import { Movistar } from '@providers/device-ks/movistar-device';
import { LocalStorage } from '@providers/local-storage/local-storage';
import { localStorageFactory } from '@providers/local-storage/local-storage.factory';

import { DeviceDetectorService } from '../device-ks/device-detector.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    CookiesService,
    { provide: APP_INITIALIZER, useValue: () => LocalStorageModule._loading, multi: true },
    DeviceDetectorService,
    LocalStorage,
    Movistar,
  ],
})
export class LocalStorageModule {
  private static _loading: Promise<boolean>;

  constructor(deviceDetector: DeviceDetectorService, localStorageService: LocalStorage, private movistar: Movistar) {
    LocalStorageModule._loading = new Promise(resolve => {
      if (deviceDetector.checkIfMovistar()) {
        const laSdk = (window as any).laSdk;
        laSdk
          .ready({
            laName: 'kanto',
          })
          .then(() => {
            this.movistar.isMovistarLoaded = true;
            this.movistar.$isReady.next(true);
            localStorageService.$ready.next(true);
            resolve(true);
          })
          .catch(() => {
            throw new Error('Movistar SDK not ready en local storage module');
          });
      } else {
        localStorageService.$ready.next(true);
        resolve(true);
      }
    });
  }
  static detect(): ModuleWithProviders<LocalStorageModule> {
    return {
      ngModule: LocalStorageModule,
      providers: [
        {
          provide: LocalStorage,
          useFactory: localStorageFactory,
          deps: [CookiesService, DeviceDetectorService],
        },
      ],
    };
  }
}
