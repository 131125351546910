import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { environment } from '@env/environment';
import {
  AnonymousSessionInterface,
  RealTimeSessionInterface,
  UserSessionResponseInterface,
} from '@interfaces/interfaces';
import { KsResponse } from '@interfaces/ks-response.interface';
import { MessageInterface } from '@interfaces/message.interface';
import { CodeInterface } from '@interfaces/user-session-response.interface';
import { FunctionsApi } from '@providers/api/functions-api';
import { DeviceKS } from '@providers/device-ks/device-ks';
import { ILaunchEventData } from '@providers/device-ks/device-platform.interface';
import {
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  Firestore,
  mainCollection,
  setDoc,
  updateDoc,
} from '@providers/firebase/firestore.functions';
import {
  collectionFromOnSnapshot,
  documentFromOnSnapshot,
  mapCollection,
  mapFilteredDocChanges,
  valueChanges,
} from '@providers/helpers/firestore';
import { RemoteSessionActionsService } from '@providers/remote-actions/remote-session-actions.service';
import { RemoteConsoleService } from '@providers/remote-console/remote-console.service';
import { BehaviorSubject, from, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, delay, filter, map, retryWhen, share, switchMap, timeout } from 'rxjs/operators';
import { TrackJS } from 'trackjs';

import { Photo } from '../../models';
import { Api } from '../api/api';
import * as helpers from '../helpers/helpers';
import { LocalStorage } from '../local-storage/local-storage';
import { MetricsService } from '../metrics/metrics.service';
import { Notifications } from '../notifications/notifications';
/**
 * Documento a guardar en Firestore
 */
export interface ITvConnectionRequestData extends ILaunchEventData {
  connected: boolean;
  sessionQR: string;
  /**
   * Token de la sesion del TV
   */
  sessionToken: string;
  premium: boolean;
}

const sessionKey = makeStateKey('session');

@Injectable()
export class SessionService {
  public session$: BehaviorSubject<AnonymousSessionInterface | UserSessionResponseInterface> = new BehaviorSubject(
    null
  );

  public realTimeSession$: BehaviorSubject<RealTimeSessionInterface> = new BehaviorSubject(null);

  sessionDoc: DocumentReference<AnonymousSessionInterface | UserSessionResponseInterface>;
  session: Observable<any>;
  sessionSub: Subscription;

  remoteCommandSubscription: Subscription;

  sessionPremium = false;

  messagesCollection: CollectionReference<MessageInterface>;
  photosCollection: CollectionReference<Photo>;

  sessionSongsCount = 0;

  remoteConsoleDoc: DocumentReference<any>;
  remoteConsoleDoc$: Observable<any>;

  /**
   * Listen Launch Device Events
   */
  launchDataSubscription: Subscription;

  constructor(
    private functionsApi: FunctionsApi,
    private api: Api,
    private firestore: Firestore,
    private localStorage: LocalStorage,
    private state: TransferState,
    private metrics: MetricsService,
    public notifications: Notifications,
    private remoteConsoleService: RemoteConsoleService,
    private deviceKs: DeviceKS,
    private remoteActionsSevice: RemoteSessionActionsService
  ) {
    const stateSession = this.state.get(sessionKey, null as any);
    if (stateSession) {
      this.setSessionData(stateSession);
    }
  }

  get currentSession(): AnonymousSessionInterface | UserSessionResponseInterface {
    return this.session$.value;
  }

  get currentRealTimeSession(): RealTimeSessionInterface {
    return this.realTimeSession$.value;
  }

  get currentSessionToken() {
    if (!this.currentSession) {
      return null;
    }
    return this.currentSession.session_tv ? this.currentSession.session_tv.token : this.currentSession.token;
  }

  getPhotosObservable(): Observable<Photo> {
    const observableValues = collectionFromOnSnapshot(this.photosCollection);

    return observableValues.pipe(
      mapFilteredDocChanges(['added']),
      map(mapCollection()),
      filter((photos: Photo[]) => photos.length > 0),
      map((photos: Photo[]) => {
        const photo = new Photo(photos[photos.length - 1]);
        photo.setRamdomPosition();
        return photo;
      })
    );
  }

  getMessagesObservable(): Observable<MessageInterface> {
    const observableValues = collectionFromOnSnapshot(this.messagesCollection);

    return observableValues.pipe(
      mapFilteredDocChanges(['added']),
      map(
        mapCollection((documentChange, data) => {
          if (documentChange.type === 'added') {
            data.picture = this.getPictureUrlFromProfile(data);
            this.notifications.notifyUserMessage(data.picture, data.user, data.text);
          }
        })
      ),
      filter(messages => messages.length > 0),
      map(messages => messages[messages.length - 1])
    );
  }

  getNewSession(tv_id: string): Observable<AnonymousSessionInterface | UserSessionResponseInterface> {
    return this.initSession(tv_id, true);
  }

  isSessionAnonymous(session: AnonymousSessionInterface | UserSessionResponseInterface): boolean {
    if (!session.session_tv) {
      return true;
    } else if (!session.id) {
      session.qr = session.session_tv.qr;
      session.token = session.session_tv.token;
      return true;
    } else {
      return false;
    }
  }
  fillSessionId(session: AnonymousSessionInterface | UserSessionResponseInterface): void {
    session.is_anonymous = this.isSessionAnonymous(session);
    if (session.is_anonymous) {
      session.session_id = session.qr;
    } else {
      session.session_id = session.session_tv.qr;
    }
  }

  initSession(tv_id: string, force?: boolean): Observable<AnonymousSessionInterface | UserSessionResponseInterface> {
    let tokenTvAux;

    const localTokenTvPromise = this.localStorage.getItem('tokenTv');

    return from(Promise.all([localTokenTvPromise])).pipe(
      switchMap(([localTokenTv]) => {
        if (localTokenTv) {
          tokenTvAux = localTokenTv;
        }
        const bodyRequestInit: any = {
          tv_id,
          token_tv: tokenTvAux,
          is_bar: true,
          name_bar: 'kanto',
          score_available: true,
        };

        Object.keys(bodyRequestInit).forEach(key => {
          if (bodyRequestInit[key] === undefined) {
            delete bodyRequestInit[key];
          }
        });

        TrackJS.console.log({
          type: 'session-init-request',
          data: bodyRequestInit,
        });

        let sessionsInit: Observable<AnonymousSessionInterface | UserSessionResponseInterface>;
        if (this.session$.value && !force) {
          sessionsInit = of(this.currentSession as any).pipe(share());
        } else {
          sessionsInit = this.api.post('sessions/init', bodyRequestInit, environment.apiExtraHeaders).pipe(
            timeout(10000),
            switchMap(res => {
              if (res.success) {
                TrackJS.console.log({
                  type: 'session-init',
                  data: res.data,
                });
                return of(res.data);
              } else {
                return throwError({
                  name: 'DataSentError',
                  message: res.message || 'Ocurrio un error inesperado DSE',
                });
              }
            }),
            retryWhen(errors =>
              errors.pipe(
                delay(3000),
                switchMap((error, i) => {
                  if (i >= 2) {
                    return throwError(error);
                  }
                  this.metrics.sendEvent('retryInitSession', {
                    message: error.message,
                    name: error.name,
                    i,
                  });
                  return of(error);
                })
              )
            ),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 401 || error.status === 500) {
                this.localStorage.removeItem('tokenUser');
                return throwError({
                  name: 'HttpError',
                  message: (error.message || 'Ocurrio un error inesperado 401') + ' - ' + error.status,
                });
              } else {
                return throwError({
                  name: 'UnexpectedHttpError',
                  message: error.message || 'Ocurrio un error inesperado N401',
                });
              }
            }),
            map((session: AnonymousSessionInterface) => {
              this.fillSessionId(session);
              return session;
            }),
            share()
          );
        }

        sessionsInit.subscribe(
          (response: AnonymousSessionInterface | UserSessionResponseInterface) => {
            this.fillSessionId(response);
            this.setSessionData(response);
          },
          (error: HttpErrorResponse) => {
            this.metrics.sendEvent('errorOnInit', {
              name: error.name,
              message: error.message,
              body_request: bodyRequestInit,
            });
            TrackJS.console.error('Error on init session', {
              name: error.name,
              message: error.message,
              body_request: bodyRequestInit,
            });
            this.session$.error(error);
          }
        );
        return sessionsInit;
      })
    );
  }

  async registerSessionDataOnMetrics() {
    this.metrics.register({
      'Token Session': await this.localStorage.getItem('tokenTv'),
      'Session QR Code': this.getQR(),
    });
  }

  setSessionData(session: AnonymousSessionInterface | UserSessionResponseInterface) {
    this.fillSessionId(session);
    this.state.set(sessionKey, session as AnonymousSessionInterface | UserSessionResponseInterface | void);
    if (!session.is_anonymous) {
      this.localStorage.setItem('tokenTv', session.session_tv.token);
      this.loadFirebaseSession(session);
    } else {
      this.localStorage.setItem('tokenTv', session.token);
      this.loadFirebaseSession(session);
    }

    this.registerSessionDataOnMetrics();

    this.doIdentify(session);
  }

  async doIdentify(userAndSession) {
    if (!userAndSession.is_anonymous) {
      await this.localStorage.setItem('tokenUser', userAndSession.token);
      await this.localStorage.setItem('emailUsed', userAndSession.email);
      this.metrics.identify(userAndSession, userAndSession.id);
      console.log('this.metrics.identify');
    }
  }

  deleteSession() {
    this.session$.next(null);
  }

  singSongOnSession() {
    this.sessionSongsCount++;
    this.metrics.unregister('Session Song Count');
    this.metrics.register({
      'Session Song Count': this.sessionSongsCount,
    });
  }

  getPlans(code: string): Observable<CodeInterface> {
    return this.api.post('plan/list/', { codigo: code }).pipe(
      switchMap((response: KsResponse<CodeInterface>) => {
        if (response.success) {
          return of(response.data);
        } else {
          return throwError(response);
        }
      })
    );
  }

  getSessionInfoByCode(code: string) {
    return this.api.post('session', { codigo: code }).pipe(
      switchMap((response: KsResponse<CodeInterface>) => {
        if (response.success) {
          return of(response.data);
        } else {
          return throwError(response);
        }
      })
    );
  }

  logoutSession(tv_id: string, tokenTV: string) {
    const bodyRequestLogout = {
      tv_id,
      is_bar: true,
      name_bar: 'kanto',
      token_tv: tokenTV,
      score_available: true,
    };
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: bodyRequestLogout,
    };
    const sessionLogout = this.api.delete('sessions/init', options).pipe(
      switchMap(res => {
        if (res.success) {
          return of(res.data);
        } else {
          return throwError(res.message ? res : { message: 'Ocurrio un error inesperado SL' });
        }
      }),
      map(session => {
        if (this.sessionSub) {
          this.sessionSub.unsubscribe();
        }
        this.deleteSession();
        return session;
      }),
      map((session: AnonymousSessionInterface) => {
        this.fillSessionId(session);
        return session;
      }),
      share()
    );
    const subSessioLogout = sessionLogout.subscribe(
      (response: AnonymousSessionInterface) => {
        this.fillSessionId(response);
        this.setSessionData(response);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        subSessioLogout ? subSessioLogout.unsubscribe() : null;
      },
      (error: HttpErrorResponse) => {
        this.metrics.sendEvent('errorOnLogout', {
          name: error.name,
          message: error.message,
          body_request: bodyRequestLogout,
        });
        this.session$.error(error);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        subSessioLogout ? subSessioLogout.unsubscribe() : null;
      }
    );
    return sessionLogout;
  }

  getToken() {
    if (!this.currentSession) {
      return null;
    }
    return this.currentSession.session_tv ? this.currentSession.session_tv.token : this.currentSession.token;
  }

  getQR() {
    if (!this.currentSession) {
      return null;
    }
    return this.currentSession.session_tv ? this.currentSession.session_tv.qr : this.currentSession.qr;
  }

  getSessionFireBase(session): DocumentReference<AnonymousSessionInterface | UserSessionResponseInterface> {
    const sessionCollection = mainCollection(this.firestore, 'sessions');
    return doc(sessionCollection, this.getSessionToken(session)) as DocumentReference<
      AnonymousSessionInterface | UserSessionResponseInterface
    >;
  }

  getSessionToken(session): string {
    if (!session) {
      return null;
    }
    return session && session.session_tv ? session.session_tv.token : session.token;
  }

  loadFirebaseSession(session) {
    try {
      this.sessionDoc = this.getSessionFireBase(session);
      this.session = documentFromOnSnapshot(this.sessionDoc).pipe(valueChanges(), share());
      this.messagesCollection = collection(this.sessionDoc, 'messages') as CollectionReference<MessageInterface>;
      this.photosCollection = collection(this.sessionDoc, 'photos') as CollectionReference<Photo>;
      this.sessionSub = this.session.subscribe((realTimeSession: RealTimeSessionInterface) => {
        if (realTimeSession) {
          this.sessionPremium = realTimeSession.is_premium;
          this.realTimeSession$.next(realTimeSession);
        }
      });
      this.remoteConsoleService.listenRemoteComands(this.sessionDoc);
      this.remoteActionsSevice.subscribeToRemoteActions(this.sessionDoc);
    } catch (e: any) {
      TrackJS.console.error('Error on loadFirebaseSession', {
        message: e?.message || 'Error on loadFirebaseSession',
      });
    }
    this.session$.next(session);
    this.listenLaunchEvents();
  }
  setRegisterFrom() {
    return this.session;
  }

  updateSession(session: Partial<AnonymousSessionInterface | UserSessionResponseInterface>) {
    return updateDoc(this.sessionDoc, session as any);
  }

  listenLaunchEvents() {
    this.unListenLaunchEvents();

    this.launchDataSubscription = this.deviceKs.devicePlatform.lauchEvent.subscribe(lEvent => {
      this.syncLaunchDataInFirestore(lEvent).catch(err => {
        console.log('error sincronizando evento lauch', err);
      });
    });
  }

  unListenLaunchEvents() {
    if (this.remoteCommandSubscription) {
      this.remoteCommandSubscription.unsubscribe();
    }
  }

  getPictureUrlFromProfile(user) {
    return helpers.getPictureUrlFromProfile(user);
  }

  getPictureUrlFromFBID(fb_id: any, username?: any) {
    return helpers.getPictureUrlFromFBID(fb_id, username);
  }

  deletePhoto(photo: Photo) {
    const photoDoc = doc(this.photosCollection, photo.id);
    deleteDoc(photoDoc);
  }

  public async syncLaunchDataInFirestore(launchData: ILaunchEventData | undefined): Promise<void> {
    if (!launchData) {
      return;
    }

    if (launchData.done) {
      return;
    }
    const requestTvConnectionsCollectionRef = mainCollection(this.firestore, 'requestTvConnections');
    const docRef = doc<Partial<ITvConnectionRequestData>>(requestTvConnectionsCollectionRef, launchData.requestTokenId);

    const data: Partial<ITvConnectionRequestData> = { ...launchData };

    const isSessionPremium = this.currentSession && this.currentSession.premium;
    data.connected = false;
    data.sessionQR = this.getQR();
    data.sessionToken = this.getSessionToken(this.currentSession);
    data.premium = isSessionPremium || false;
    launchData.done = true;
    await setDoc(docRef, data, { merge: true });
  }
}
