import { CookiesService } from '@providers/cookies/cookies.service';
import { DeviceDetectorService } from '@providers/device-ks/device-detector.service';
import { MovistarLocalStorageService } from '@providers/device-ks/movistar/movistar-local-storage.service';

import { LocalStorage } from './local-storage';

export const localStorageFactory = (
  cookies: CookiesService,
  deviceDetector: DeviceDetectorService
) => {
  if (deviceDetector.checkIfMovistar()) {
    return new MovistarLocalStorageService(cookies);
  } else {
    return new LocalStorage(cookies);
  }
};
