import { Injectable } from '@angular/core';
import { debounceAsync } from '@providers/helpers/helpers';
import { CustomerIoService } from '@providers/providers';
import { clone } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';

import { Mxp } from '../mxp/mxp';
import { User } from '../user/user';
import { HotjarService } from './hotjar/hotjar.service';

export interface UserInforForMetricsData {
  'Personal ID': string;
  'FB ID': string;
  'First Name': string;
  'User ID': string | number;
  Email: string;
  'Email Valid': boolean;
  'Is Follow': boolean;
  'Last Name': string;
  Username: string;
  Premium: boolean;
  'User Token': string;
  'Profile Picture': string;
  'Phone Number': string;
  Address: string;
}

@Injectable()
export class MetricsService {
  public static eventNames = {
    pasarelaExito: 'pasarelaExito',
  };

  private _registerMpxImitation: Record<string, any> = {};

  private lastErrorSongId$ = new BehaviorSubject<string | null>(null);

  private identifyDebounce = debounceAsync(this.identifyFn, 500);

  constructor(private mxp: Mxp, private hotjar: HotjarService, private customerIo: CustomerIoService) {}

  async sendEvent(name: string, data?: any) {
    await this.sendCleanedEvent(name, data);
  }

  async sendCleanedEvent(name: string, data?) {
    const dataCloned = clone(data);
    if (dataCloned) {
      if (dataCloned.time) {
        delete dataCloned.time; // This attribute rises error "time, 19972.9 days in the past" in mixpanel
      }
      if (dataCloned.email && !dataCloned.Email) {
        dataCloned.Email = dataCloned.email;
        delete dataCloned.email;
        this.unregister('email');
        //console.log(dataCloned, '+++++  reemplazado 1');
      }
      //console.log('---',data);
      if (name === 'openSesion' && dataCloned.idUser) {
        this.mxp.distinct_id = dataCloned.idUser;
      }
    }

    this.mxp.sendEvent(name, dataCloned);
    await this.customerIo.sendEvent(name, { ...this._registerMpxImitation, ...dataCloned });
  }

  getDistinctId() {
    return this.mxp.distinct_id;
  }

  /**
   * @deprecated
   **/
  setParametersDevices(device, userService: User) {
    if (userService.authenticated) {
      //TODO: agregar device data en hotjar
      this.hotjar.identify({ ...userService.currentUser }, userService.currentUser.id);
    }
  }

  timeLoadApp(country: string) {
    this.mxp.timeLoadApp(country);
  }

  reset() {
    this.mxp.resetMxp();
    this.customerIo.reset();
    this._registerMpxImitation = {};
  }

  abrioApp(serialNumber?: string) {
    console.log('abrioApp METRICS');
    this.mxp.emitAbrioApp(serialNumber);
  }

  register(data, userId?) {
    const dataClone = clone(data);
    if (dataClone.email && !dataClone.Email) {
      dataClone.Email = dataClone.email;
      delete dataClone.email;
      this.unregister('email');
      console.log(dataClone, '+++++ reemplazado 3');
    }
    this.mxp.register(dataClone, userId);
    this._registerMpxImitation = { ...this._registerMpxImitation, ...dataClone };
  }

  peopleSet(data) {
    this.mxp.peopleSet(data);
  }

  peopleIncrement(prop, by?) {
    this.mxp.peopleIncrement(prop, by);
  }

  unregister(property: string) {
    this.mxp.unregister(property);
    delete this._registerMpxImitation[property];
  }

  ViewModals(data) {
    if (data) {
      this.sendEvent('ViewModals-' + data);
    }
  }

  viewClicked(view, data) {
    if (data && view === 'btnPayment' && data.from === 'modal') {
      return;
    }
    this.sendEvent(view, data);
  }

  errorVideo(codeError, messageError, song) {
    let url = song.src;
    if (!song.src) {
      url = song.sources && song.sources.length ? song.sources[0].src : song;
    }
    if (song && song.id !== this.lastErrorSongId$.value) {
      this.lastErrorSongId$.next(song.id);
      this.sendEvent('errorVideo', {
        codeError,
        messageError,
        url,
        song: {
          name: song.name || song.nameSong,
          idSong: song.id,
          artist: song.artist || song.artist_name,
        },
      });
    }
  }

  unregisterUser() {
    this.unregister('idUser');
    this.unregister('email');
    this.unregister('phone');
    this.unregister('Premium');
    this.unregister('fullName');
  }

  async identify(data: Record<string, any>, userId: string) {
    const localUserId = userId || data.id || data.idUser;
    const localEmail = data.email || data.Email;

    const dataForMetrics: UserInforForMetricsData = {
      'Personal ID': data.cfi,
      Email: localEmail,
      'FB ID': data.fb_id,
      'First Name': data.first_name,
      'User ID': localUserId,
      'Email Valid': data.is_valid,
      'Is Follow': data.is_follow,
      'Last Name': data.last_name,
      Username: data.user_name,
      Premium: data.premium,
      'User Token': data.token,
      'Profile Picture': data.img,
      'Phone Number': data.phone_number,
      Address: data.address,
    };

    this._registerMpxImitation = {
      ...this._registerMpxImitation,
      ...dataForMetrics,
    };
    this.identifyDebounce.cancel();
    await this.identifyDebounce
      .debounced(
        {
          hotjar: {
            identify: this.hotjar.identify.bind(this.hotjar),
            data: { ...this._registerMpxImitation, ...data },
          },
          mxp: {
            identify: this.mxp.identify.bind(this.mxp),
            data: { ...dataForMetrics },
          },
          customerIo: {
            identify: this.customerIo.identify.bind(this.customerIo),
            data: { ...this._registerMpxImitation },
          },
        },
        userId
      )
      .catch(error => {
        console.error('this.metrics.identify  error', error);
      });
  }

  private async identifyFn(context, userId) {
    context.hotjar.identify(context.hotjar.data, userId);
    context.mxp.identify(context.mxp.data, userId);
    await context.customerIo.identify(context.customerIo.data, context.customerIo.data.Email);
  }
}
