// angular
import { APP_BOOTSTRAP_LISTENER, NgModule } from '@angular/core';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { ChromecastService } from '@providers/chromecast/chromecast.service';
import {
  devicePlatformBootstrapFactory,
  DevicePlatformInitializer,
} from '@providers/device-ks/device-platform.initializer';

import { AppComponent } from './app.component';
import { AppModule } from './app.module';

// the Request object only lives on the server
export const getRequest = (): any => ({ headers: { cookie: document.cookie } });

@NgModule({
  bootstrap: [AppComponent],
  imports: [AppModule, BrowserTransferStateModule],
  providers: [
    ChromecastService,
    {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: devicePlatformBootstrapFactory,
      deps: [DevicePlatformInitializer],
      multi: true,
    },
    { provide: 'ORIGIN_URL', useValue: location.origin },
  ],
})
export class AppBrowserModule {}
