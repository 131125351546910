import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AppConfigInterface } from '@interfaces/app-config.interface';
import { doc, Firestore, getDoc, mainCollection } from '@providers/firebase/firestore.functions';
import { BehaviorSubject, from } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class RemoteConfigService {
  currentConfig$: BehaviorSubject<AppConfigInterface | undefined> = new BehaviorSubject<AppConfigInterface | undefined>(
    undefined
  );

  get currentConfig(): AppConfigInterface | undefined {
    return this.currentConfig$.value ? this.currentConfig$.value : undefined;
  }

  set currentConfig(config: AppConfigInterface | undefined) {
    this.currentConfig$.next(config);
  }

  constructor(private firestore: Firestore, private http: HttpClient) {}

  async load() {
    const defaultCollection = mainCollection(this.firestore, environment.defaultConfig);
    const defaultRef = doc(defaultCollection, 'default');
    const customerServicePhonesRef = doc(defaultCollection, 'customerServicePhones');
    const promiseDefault = from(getDoc(defaultRef))
      .pipe(timeout(5000))
      .toPromise()
      .then(snapshot => {
        const data: any = snapshot.data();
        if (data && data.promoCounter && data.promoCounter.seconds) {
          data.promoCounter = new Date(data.promoCounter.seconds * 1000);
        }
        const currentTime = data.promoCounter.getTime();
        const hoursZoneOffset = data.promoCounter.getTimezoneOffset() / 60;
        if (data) {
          data.promoCounter = new Date(currentTime + ((data?.gmtZone || 0) + hoursZoneOffset) * 3600 * 1000);
        }
        return data;
      })
      .catch(() => ({ customerServicePhones: {} }));
    const promiseCustomerServicePhones = from(getDoc(customerServicePhonesRef))
      .pipe(timeout(5000))
      .toPromise()
      .then(snapshot => snapshot.data())
      .catch(() => ({}));
    return Promise.all([promiseDefault, promiseCustomerServicePhones])
      .then(([data, customerServicePhones]: [any, any]) => {
        this.currentConfig = { ...data, customerServicePhones };
      })
      .catch(() => {});
  }
}

export const remoteConfigFactory =
  (service: RemoteConfigService): (() => Promise<any>) =>
  () =>
    service.load();
