import { AppState, GlobalScopeState } from '@app/state/app.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const appState = createFeatureSelector<AppState>('globalScope');
export const selectGlobalScope = createSelector(appState, (state: AppState) => state.globalScope);

const globalScopeState = createFeatureSelector<GlobalScopeState>('globalScope');
export const getIsLoading = createSelector(globalScopeState, (state: GlobalScopeState) => state.isLoading);
export const getGoOutApp = createSelector(globalScopeState, (state: GlobalScopeState) => state.goOutApp);
export const getActiveFastPlay = createSelector(globalScopeState, (state: GlobalScopeState) => state.activeFastPlay);
export const getLastSearchField = createSelector(globalScopeState, (state: GlobalScopeState) => state.lastSearchField);
