export interface GlobalScopeState {
  goOutApp: boolean;
  activeFastPlay: boolean;
  lastSearchField: string;
  isLoading: boolean;
}

export const initialGlobalScopeState: GlobalScopeState = {
  goOutApp: false,
  activeFastPlay: false,
  lastSearchField: '',
  isLoading: false,
};

export interface AppState {
  globalScope: GlobalScopeState;
  currentTrack: any;
}

export const initialState: AppState = {
  globalScope: initialGlobalScopeState,
  currentTrack: null,
};
