import 'firebase/database';
import 'firebase/firestore';

import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { environment } from '../../environments/environment';

@NgModule({
  imports: [AngularFireModule.initializeApp(environment.firebase), AngularFireDatabaseModule, AngularFirestoreModule],
  exports: [],
  declarations: [],
  providers: [],
})
export class SharedModule {}
