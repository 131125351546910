import { Router } from '@angular/router';
import { SessionService } from '@providers/providers';
import { User } from '@providers/user/user';
import { TrackJS } from 'trackjs';

import { Basic } from './device-platform';
import { AllowedDevicePlatformsType, IDevicePlatform, ILaunchEventData } from './device-platform.interface';

export interface IMessageData<TAttributes = Partial<Record<string, string>>> {
  attributes: TAttributes;
  connectTime: number;
  deviceName: string;
  isHost: boolean;
  id: string;
}

/**
 * Tipo del evento.
 */
export type ChannelEventType = 'ms.channel.connect' | 'ms.channel.clientConnect' | string;

export interface IMessagePayload<TData extends any = Partial<Record<string, string | boolean | number>>> {
  data: TData;
  /**
   * ID del evento
   */
  event: ChannelEventType;
}

export interface IClientConnectMessagePayload extends IMessagePayload<IMessageData<IChannelClientConnectAttributes>> {
  event: 'ms.channel.clientConnect';
}

export interface IConnectMessagePayload
  extends IMessagePayload<{
    clients: IMessageData<IChannelConnectAttributes>[];
    id: string;
  }> {
  event: 'ms.channel.connect';
}

/**
 * Verifica si el evento es `ms.channel.connect`.
 */
export const eventIsConnect = (payload: Pick<IMessagePayload, 'event'>): payload is IConnectMessagePayload =>
  //
  payload.event === 'ms.channel.connect';

/**
 * Verifica si el evento es `ms.channel.clientConnect`.
 */
export const eventIsClientConnect = (
  payload: Pick<IMessagePayload, 'event'>
): payload is IClientConnectMessagePayload =>
  //
  payload.event === 'ms.channel.clientConnect';

/**
 * Atributos para el evento `ms.channel.connect`
 */
export interface IChannelConnectAttributes extends Partial<ILaunchEventData> {
  // /**
  //  * // FIXME: Verificar que no sea requestToquenId
  //  */
  // uuid?: string;
  /**
   * Sólo se especifica para manualmente para pruebas
   */
  env?: 'test' | 'local' | 'prod';
}

/**
 * Atributos para el evento `ms.channel.clientConnect`
 */
export interface IChannelClientConnectAttributes extends ILaunchEventData {
  email: string;
  name: string;

  userId: string;
  // uuid?: string; // FIXME: Este atributo no se envía nunca desde el móvil
  /**
   * Sólo se especifica para manualmente para pruebas
   */
  env?: 'test' | 'local' | 'prod';
}

export class Tizen extends Basic implements IDevicePlatform {
  name = 'tizen';
  type = AllowedDevicePlatformsType.tizen;

  deviceObject: any = {};
  deviceData: any = {};

  onRelaunch() {
    this.listenRelaunch();
  }

  listenRelaunch() {
    if (!!this.relaunchSubscription) {
      this.relaunchSubscription();
    }

    // TODO: Inject LocalStorageService

    const $localStorage = window.localStorage;
    const currentToken = $localStorage.getItem('token');
    const currentDeviceUuid = $localStorage.getItem('deviceUuid');

    const urlWebsocketSamsung = 'ws://127.0.0.1:8001/api/v2/channels/kanto';
    // const urlWebsocketSamsung = 'ws://192.168.100.85:8001/api/v2/channels/kanto';

    try {
      const connection = new WebSocket(urlWebsocketSamsung);

      connection.onmessage = event => {
        const messagePayload: IMessagePayload = JSON.parse(event.data);

        console.log('😉 messageData', messagePayload);

        if (eventIsConnect(messagePayload)) {
          // check if exists clients
          if (messagePayload.data.clients.length > 0) {
            // find the client with token user
            messagePayload.data.clients.forEach(client => {
              if (client.isHost || !client.attributes) {
                return;
              }

              const { tokenUser, requestTokenId } = client.attributes;

              if (!tokenUser || !requestTokenId) {
                console.warn(`Evento '${messagePayload.event}' enviado sin los atributos necesario.`);

                return;
              }

              // TODO: Verificar si este condicional es necesario, ya que deben guardarse
              // incluso el actual si no lo está en Firestore. La comprobación debería hacerse en el consumidor
              // if (
              //   client.attributes.tokenUser !== currentToken ||
              //   client.attributes.requestTokenId !== currentDeviceUuid
              // ) {
              // }
              this.lauchEvent.next({
                tokenUser,
                requestTokenId,
              });
            });
          }
        }

        if (eventIsClientConnect(messagePayload)) {
          if (!messagePayload.data.isHost || messagePayload.data.attributes.env === 'test') {
            const webSocketTokenUser = messagePayload.data.attributes.tokenUser;
            const webSocketDeviceUuid = messagePayload.data.attributes.requestTokenId;
            // const webSocketDeviceUuid = messagePayload.data.attributes.uuid;

            if (webSocketTokenUser !== currentToken || webSocketDeviceUuid !== currentDeviceUuid) {
              this.lauchEvent.next({
                tokenUser: webSocketTokenUser,
                requestTokenId: webSocketDeviceUuid,
              });
            }
          }
        }
      };

      connection.onerror = err => {
        console.error('Connection error', err);
        // TODO: Launch
      };
    } catch (e) {
      console.error('🦑 Error al conectar con el websocket', e);
    }
  }

  async onInit() {
    super.onInit();
    const { webapis, tizen } = window as {
      webapis?: {
        [p: string]: any;
        productinfo: any;
      };
      tizen?: any;
    };

    console.log('🎈 tizen', tizen, webapis);

    if (this.force) {
      this.deviceUid = `FORCED_Tizen-UID-1234`;
      this.deviceObject = {
        type: `FORCED_Tizen`,
        platform: 'tizen',
        modelName: 'FORCED_Tizen',
        serialNumber: 'FORCED_Tizen-SERIAL#-1234',
        SDKVersion: 'FORCED_3.1',
        tizen_tv: true,
        tizen_Firmware: 'FORCED_Tizen-Firmware',
        tizen_Model: 'FORCED_Tizen-Model',
        tizen_LocalSet: 'FORCED_LocalSet',
        tizen_Version: 'FORCED_3.1',
      };

      this.deviceData = { ...this.deviceObject };

      return Promise.resolve(this.deviceObject);
    }

    try {
      const productinfo = webapis?.productinfo ? webapis?.productinfo : webapis?.tv.info;
      this.deviceData.serialNumber = productinfo ? productinfo.getDuid() : webapis?.tv.info.getDeviceID();
      this.deviceData.modelName = productinfo
        ? productinfo.getRealModel()
        : (window as any).deviceapis?.tv.info.getModel();
      this.deviceData.SDKVersion = productinfo?.getVersion();
      this.deviceData.tizen_tv = true;
      this.deviceData.tizen_Firmware = productinfo?.getFirmware();
      this.deviceData.tizen_Model = productinfo
        ? productinfo.getRealModel()
        : (window as any).deviceapis?.tv.info.getModel();
      this.deviceData.tizen_LocalSet = productinfo?.getLocalSet();
      this.deviceData.tizen_Version = productinfo?.getVersion();
    } catch (e) {
      if (!this.deviceData) {
        this.deviceData = {};
      }
      console.warn(e);
      TrackJS.track(e);
    }
  }

  onResume(parameters: { user: User; session: SessionService; router: Router }) {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        // Behavior when application is hidden
      } else {
        // Behavior when application is resumed

        // window.location.assign("/tizenV3/")
        const reInit = () => {
          parameters.user.reinitSession();
          parameters.router.navigate(['/']);
        };

        if (parameters.session.getToken()) {
          parameters.user.requestKeepalive(parameters.session.getToken()).subscribe(
            response => {
              if (!response.success) {
                // cuando la sesion ya es invalida al reabrir el app
                reInit();
              }
            },
            () => {
              reInit();
            }
          );
        } else {
          parameters.router.navigate(['welcome']);
        }
      }
    });
  }

  onMediaKeys() {
    try {
      (window as any).tizen.tvinputdevice.registerKeyBatch(['MediaPlayPause', 'MediaPause', 'MediaPlay', 'MediaStop']);
    } catch (e) {}
  }

  exit() {
    try {
      (window as any).tizen.application.getCurrentApplication().exit();
    } catch (e) {
      window.history.go(-999);
      window.close();
    }
  }
}
