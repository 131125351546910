import { Alertify } from './alertify/alertify';
import { Api } from './api/api';
import { Config } from './config/config';
import { CountryService } from './country/country';
import { DeviceKS } from './device-ks/device-ks';
import { LocationService } from './helpers/location';
import { CustomerIoService } from './metrics/customer-io/customer-io.service';
import { HotjarService } from './metrics/hotjar/hotjar.service';
import { MetricsService } from './metrics/metrics.service';
import { Mxp } from './mxp/mxp';
import { Notifications } from './notifications/notifications';
import { RemoteSessionActionsService } from './remote-actions/remote-session-actions.service';
import { RemoteConsoleService } from './remote-console/remote-console.service';
import { AuthTokenService } from './user/auth-token.service';
import { PremiumCheckerService } from './user/premium-checker.service';
import { SessionService } from './user/session.service';
import { User } from './user/user';
import { UserRealtimeService } from './user/user-realtime';

export const boostrapProviders = [
  //Notifications
  Alertify,
  Notifications,
  //Core Services
  Api,
  AuthTokenService,
  Config,
  CountryService,
  DeviceKS,
  LocationService,
  //Metrics and Logging
  Mxp,
  HotjarService,
  CustomerIoService,
  MetricsService,
  //Session Services
  SessionService,
  RemoteSessionActionsService,
  RemoteConsoleService,
  PremiumCheckerService,
  User,
  UserRealtimeService,
];
