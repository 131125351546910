import { BehaviorSubject, fromEvent } from 'rxjs';

import { Basic } from './device-platform';
import { AllowedDevicePlatformsType, IDevicePlatform } from './device-platform.interface';
import { voiceContexts } from './movistar/voiceContext';

// Movistar Documentation ---> https://la-docs.apps.ocp-epg.tid.es/docs/sdk
export class Movistar extends Basic implements IDevicePlatform {
  //basic
  $isReady = new BehaviorSubject<boolean>(false);
  name = AllowedDevicePlatformsType.movistar;
  type = AllowedDevicePlatformsType.movistar;

  deviceUid = '';
  deviceObject: any = {};
  deviceData: any = {};

  //movistar
  isMovistarLoaded = false;
  channel: 'movistar-home' | 'set-top-box-haac' | 'no-channel' = 'no-channel';

  SDK: any;

  get ready() {
    return this.$isReady.value;
  }

  // movistar listeners
  private $keyPressEvents = fromEvent(window, 'lakeypress');
  private $voiceEvents = fromEvent(window, 'lavoiceevent');
  private $channelSync = fromEvent(window, 'lachannelsync');

  //movistar methods
  async onInit() {
    console.log('MOVISTAR ON INIT SE EJECUTA');
    try {
      if (this.force) {
        this.deviceUid = `FORCED_Movistar-UID-1234`;
        this.channel = 'set-top-box-haac';
        this.isMovistarLoaded = true;
        this.deviceObject = {
          duid: this.deviceUid,
          deviceUid: this.deviceUid,
          type: `FORCED_Movistar`,
          platform: 'movistar',
          modelName: 'FORCED_Movistar',
          serialNumber: 'FORCED_Movistar-SERIAL#-1234',
        };
        this.deviceData = { ...this.deviceObject };
        super.onInit();

        return Promise.resolve(this.deviceObject);
      }

      this.SDK = (window as any).laSdk;

      await this.SDK.ready({
        laName: 'kanto',
      })
        .then(() => {
          console.log('LA SDK MOVISTAR ESTA LISTO');
          this.deviceData = {
            duid: this.SDK.userData?.deviceId,
            deviceUid: this.SDK.userData?.deviceId,
          };
          this.deviceUid = this.SDK.userData?.deviceId;
          this.$isReady.next(true);
          this.isMovistarLoaded = true;
          this.channel = this.SDK.channel;
          this.subscribeToVoiceEvent();
          this.subscribeToChannelSync();
        })
        .catch(error => {
          console.log('error de movistar ready');
          console.error(error);
        });
    } catch (err) {
      console.log('error al cargar el SDK de movistar', err);
    }
    super.onInit();
  }

  sendKey(keyCode: number) {
    return this.SDK.sendKey(keyCode);
  }

  get keyCode() {
    return this.SDK.KEYS;
  }

  sendText(text: string) {
    return this.SDK.sendText(text);
  }

  enableVoiceCommands(context: string) {
    if (this.force) {
      return voiceContexts[context];
    }
    // context dialog is a object
    return this.SDK.setDialogContext(voiceContexts[context]);
  }

  clearDialogContext() {
    return this.SDK.clearDialogContext();
  }

  createSuggestions(suggestions: [{ text: string; onClick: () => void; up: string }], voice?: boolean) {
    return this.SDK.createSuggestions(suggestions, voice);
  }

  removeSuggestions() {
    return this.SDK.removeSuggestions();
  }

  persistanceWrite(key: string, value: string) {
    return this.SDK.persistenceWrite(key, value);
  }

  persistanceRead(key: string) {
    return this.SDK.persistenceRead(key);
  }

  sendToMh(data: any) {
    return this.SDK.sendMh(data);
  }

  sendToSTB(data: any) {
    return this.SDK.sendStb(data);
  }

  userData() {
    return this.SDK.userData;
  }

  createToast(toast: {
    text: string;
    type: 'info' | 'error' | 'success' | 'warning' | 'loading';
    duration?: number;
    code: string;
  }) {
    return this.SDK.createToast(toast);
  }

  removeToast() {
    return this.SDK.removeToast();
  }

  currentChannel() {
    if (this.force) {
      return this.channel;
    }
    return this.SDK.channel;
  }

  createaLogger(name: string) {
    return this.SDK.createLogger(name);
  }

  //custom methods
  subscribeToVoiceEvent() {
    if (this.force) {
      return;
    }
    this.$voiceEvents.subscribe((event: any) => {
      console.log('voice event', event);
      if (event.detail.event === 'up') {
        // @ts-ignore
        const eventKey = new KeyboardEvent('keydown', { which: 38, keyCode: 38, charCode: 38 });
        document.dispatchEvent(eventKey);
        return;
      }
      if (event.detail.event === 'dowm') {
        // @ts-ignore
        const eventKey = new KeyboardEvent('keydown', { which: 40, keyCode: 40, charCode: 40 });
        document.dispatchEvent(eventKey);
        return;
      }
      if (event.detail.event === 'right') {
        // @ts-ignore
        const eventKey = new KeyboardEvent('keydown', { which: 39, keyCode: 39, charCode: 39 });
        document.dispatchEvent(eventKey);
        return;
      }
      if (event.detail.event === 'left') {
        // @ts-ignore
        const eventKey = new KeyboardEvent('keydown', { which: 37, keyCode: 37, charCode: 37 });
        document.dispatchEvent(eventKey);
        return;
      }
      if (event.detail.event === 'enter') {
        // @ts-ignore
        const eventKey = new KeyboardEvent('keydown', { which: 13, keyCode: 13, charCode: 13 });
        document.dispatchEvent(eventKey);
        return;
      }
      if (event.detail.event === 'back') {
        // @ts-ignore
        const eventKey = new KeyboardEvent('keydown', { which: 8, keyCode: 8, charCode: 8 });
        document.dispatchEvent(eventKey);
        return;
      }
    });
  }

  subscribeToChannelSync() {
    if (this.force) {
      return;
    }
    this.$channelSync.subscribe((event: any) => {
      console.log('channel sync data', event);

      if (event.detail.data.origin === 'mh') {
        if (event.detail.data.toScreen === 'volver') {
          this.sendText(event.detail.data.toScreen);
        } else {
          this.$deviceEvent.next({
            type: 'channelSync',
            action: 'navigateTo',
            data: event.detail.data.toScreen,
          });
        }
      }
    });
  }
  //extends methods
  onResume(router) {
    //TODO: implementar onResume
  }
  exit() {
    if (this.force) {
      (window as any).close();
      return;
    }
    this.SDK.close();
  }
}
