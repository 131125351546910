import { KsResponse } from '@interfaces/ks-response.interface';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

export const makeHttpRequest: (
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
  body: any
) => Promise<any> = (url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH', body?: any) =>
  // make XRHttpRequest
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    // xhr.setRequestHeader('Authorization', `Bearer ${this.signatureToken}`);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(xhr.responseText);
        }
      }
    };
    xhr.send(JSON.stringify(body));
  });

export const proccessBackendResponse: <T>() => (source: Observable<any>) => Observable<T> =
  <T>() =>
  (source: Observable<any>) =>
    source.pipe(
      switchMap((response: KsResponse<T>) => {
        if (response.success === true) {
          return of(response.data);
        } else {
          return throwError(response.message ? response : { code: 'unknown', message: 'Ocurrio un error inesperado' });
        }
      }),
      catchError(error => {
        if (error.status) {
          return throwError(
            error.message ? error : { code: error.status, message: 'Ocurrio un error inesperado CPBR' }
          );
        }
        return throwError(error.message ? error : { code: 'unknown', message: 'Ocurrio un error inesperado NCPBR' });
      })
    );
